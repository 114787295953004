// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-diff-added {
  background-color: rgb(171, 242, 188);
  text-decoration: none;
}

.doc-diff-modified {
}

.doc-diff-removed {
  background-color: rgba(255, 129, 130, 0.4);
  text-decoration: none;
}

.doc-diff-chunk-selected {
  background-color: rgba(254, 250, 212, 0.6);
}
`, "",{"version":3,"sources":["webpack://./src/docdiff.document.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,qBAAqB;AACvB;;AAEA;AACA;;AAEA;EACE,0CAA0C;EAC1C,qBAAqB;AACvB;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".doc-diff-added {\n  background-color: rgb(171, 242, 188);\n  text-decoration: none;\n}\n\n.doc-diff-modified {\n}\n\n.doc-diff-removed {\n  background-color: rgba(255, 129, 130, 0.4);\n  text-decoration: none;\n}\n\n.doc-diff-chunk-selected {\n  background-color: rgba(254, 250, 212, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
