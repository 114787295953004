// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  display: none;
  max-width: 650px;
  pointer-events: auto;
  z-index: 9999999;
  max-height: 80vh;
  min-height: 100px;
  position: absolute;
  margin-bottom: 20px;
  top: 0;
  border: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
}

.tooltip .tooltip-content {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 18px;
  color: #8d8d8d;
  font-size: 80%;
  line-height: 18px;
}

.tooltip .tooltip-content .headerlink {
  visibility: hidden;
}

.tooltip .tooltip-content p {
  font-size: 100%;
}

.tooltip .arrow {
  position: absolute;
  background: #fff;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.link-preview {
  border-bottom: 1px dotted;
}
`, "",{"version":3,"sources":["webpack://./src/linkpreviews.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,MAAM;EACN,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,2CAA2C;AAC7C;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,aAAa;EACb,cAAc;EACd,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".tooltip {\n  display: none;\n  max-width: 650px;\n  pointer-events: auto;\n  z-index: 9999999;\n  max-height: 80vh;\n  min-height: 100px;\n  position: absolute;\n  margin-bottom: 20px;\n  top: 0;\n  border: none;\n  border-radius: 5px;\n  background: #fff;\n  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);\n}\n\n.tooltip .tooltip-content {\n  box-sizing: border-box;\n  max-height: 100%;\n  max-width: 100%;\n  overflow: auto;\n  padding: 18px;\n  color: #8d8d8d;\n  font-size: 80%;\n  line-height: 18px;\n}\n\n.tooltip .tooltip-content .headerlink {\n  visibility: hidden;\n}\n\n.tooltip .tooltip-content p {\n  font-size: 100%;\n}\n\n.tooltip .arrow {\n  position: absolute;\n  background: #fff;\n  width: 8px;\n  height: 8px;\n  transform: rotate(45deg);\n}\n\n.link-preview {\n  border-bottom: 1px dotted;\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
