// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Specific styles based on documentation tools and themes
 *
 * Usage of \`@layer\` at-rule pushes this rules down a step in
 * precedence/priority. This allows a user \`:root\` rule to override these
 * values.
 **/
@layer defaults {
  :root[data-readthedocs-tool="docusaurus"] {
    --readthedocs-flyout-header-font-size: 0.9rem;
  }

  :root[data-readthedocs-tool="mkdocs-material"] {
    --readthedocs-font-size: 0.58rem;
    --readthedocs-flyout-header-font-size: 0.7rem;
    --readthedocs-flyout-font-size: 0.58rem;
  }

  :root[data-readthedocs-tool="antora"] {
    --readthedocs-font-size: 0.7rem;
    --readthedocs-flyout-header-font-size: 0.8rem;
    --readthedocs-flyout-font-size: 0.7rem;
  }

  :root[data-readthedocs-tool="mdbook"] {
    --readthedocs-font-size: 1.3rem;
    --readthedocs-flyout-header-font-size: 1.5rem;
    --readthedocs-flyout-font-size: 1.3rem;
  }

  :root[data-readthedocs-tool="sphinx"][data-readthedocs-tool-theme="furo"] {
    --readthedocs-font-size: 0.725rem;
    --readthedocs-flyout-header-font-size: 0.845rem;
    --readthedocs-flyout-font-size: 0.725rem;
  }

  :root[data-readthedocs-tool="sphinx"][data-readthedocs-tool-theme="immaterial"] {
    --readthedocs-font-size: 0.58rem;
    --readthedocs-flyout-header-font-size: 0.7rem;
    --readthedocs-flyout-font-size: 0.58rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/doctools.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;EACE;IACE,6CAA6C;EAC/C;;EAEA;IACE,gCAAgC;IAChC,6CAA6C;IAC7C,uCAAuC;EACzC;;EAEA;IACE,+BAA+B;IAC/B,6CAA6C;IAC7C,sCAAsC;EACxC;;EAEA;IACE,+BAA+B;IAC/B,6CAA6C;IAC7C,sCAAsC;EACxC;;EAEA;IACE,iCAAiC;IACjC,+CAA+C;IAC/C,wCAAwC;EAC1C;;EAEA;IACE,gCAAgC;IAChC,6CAA6C;IAC7C,uCAAuC;EACzC;AACF","sourcesContent":["/*\n * Specific styles based on documentation tools and themes\n *\n * Usage of `@layer` at-rule pushes this rules down a step in\n * precedence/priority. This allows a user `:root` rule to override these\n * values.\n **/\n@layer defaults {\n  :root[data-readthedocs-tool=\"docusaurus\"] {\n    --readthedocs-flyout-header-font-size: 0.9rem;\n  }\n\n  :root[data-readthedocs-tool=\"mkdocs-material\"] {\n    --readthedocs-font-size: 0.58rem;\n    --readthedocs-flyout-header-font-size: 0.7rem;\n    --readthedocs-flyout-font-size: 0.58rem;\n  }\n\n  :root[data-readthedocs-tool=\"antora\"] {\n    --readthedocs-font-size: 0.7rem;\n    --readthedocs-flyout-header-font-size: 0.8rem;\n    --readthedocs-flyout-font-size: 0.7rem;\n  }\n\n  :root[data-readthedocs-tool=\"mdbook\"] {\n    --readthedocs-font-size: 1.3rem;\n    --readthedocs-flyout-header-font-size: 1.5rem;\n    --readthedocs-flyout-font-size: 1.3rem;\n  }\n\n  :root[data-readthedocs-tool=\"sphinx\"][data-readthedocs-tool-theme=\"furo\"] {\n    --readthedocs-font-size: 0.725rem;\n    --readthedocs-flyout-header-font-size: 0.845rem;\n    --readthedocs-flyout-font-size: 0.725rem;\n  }\n\n  :root[data-readthedocs-tool=\"sphinx\"][data-readthedocs-tool-theme=\"immaterial\"] {\n    --readthedocs-font-size: 0.58rem;\n    --readthedocs-flyout-header-font-size: 0.7rem;\n    --readthedocs-flyout-font-size: 0.58rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
